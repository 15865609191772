import React, { useCallback, useEffect, useRef } from "react";
import Slider from "react-slick";
import { useTheme } from "@emotion/react";
import useSectionOBserver from "@/hooks/useSectionObserver";
import Typography from "@/components/Typography";
import { ServicesWrapper, ServicesTypography, ServicesSlider } from "./styled";
import CalcIcon from "@/assets/svg/calc.svg";
import MEIIcon from "@/assets/svg/mei.svg";
import AccountabilityIcon from "@/assets/svg/accountability.svg";
import { setHeaderColors } from "@/theme/base/utils";
import useResolution from "@/hooks/useResolution";
import baseTheme from "@/theme/base";
import ServicesBox from "./components/ServicesBox";

const servicesItems = [
  {
    id: "b3-calc",
    title: "Contabilidade Para Empresas",
    content: `Na LEAN, vamos cuidar de toda a burocracia da sua empresa. Dessa forma, você consegue focar naquilo que realmente importa.`,
    link: "/",
    Icon: CalcIcon,
  },
  {
    id: "mei",
    title: "MEI",
    content: `Na LEAN, analisamos detalhadamente seu negócio para enquadrar no tipo de atividade adequada e abrimos seu MEI no mesmo dia e de maneira totalmente gratuita. A partir daí, fazemos todo o acompanhamento mensal.`,
    link: "/",
    Icon: MEIIcon,
  },
  {
    id: "accountability",
    title: "Processos Societários",
    content: `Buscando adequar sua empresa e lhe auxiliar, conforme a legislação vigente, somos especialistas nos processos societários.`,
    link: "/",
    Icon: AccountabilityIcon,
  },
  {
    id: "tradetax-carousel",
    title: "TRADERTAX",
    content: `Simplifique a apuração dos seus impostos de bolsa de valores com precisão e segurança. Aprovado por clientes, assessores e professores de investimento. `,
    link: "/",
    Icon: CalcIcon,
  },
  {
    id: "e-social",
    title: "E-SOCIAL",
    content: `Aproveite o alívio de estar cumprindo todas as obrigações legais de um empregador doméstico.`,
    link: "/",
    Icon: MEIIcon,
  },
  {
    id: "irpf",
    title: "IRPF",
    content: `Conte com uma assessoria especializada para cuidar da sua declaração anual de Imposto de Renda. Nosso time está pronto para te atender.`,
    link: "/",
    Icon: AccountabilityIcon,
  },
];

const clonedServicesItems = Array.from({
  length: servicesItems.length * 2,
}).map((_, index: number) => servicesItems[index % servicesItems.length]);

const renderServicesBox = (items: any) =>
  items.map(({ id, title, content, link, Icon }: any) => (
    <ServicesBox
      key={id}
      title={title}
      content={content}
      link={link}
      icon={<Icon />}
    />
  ));

function Services() {
  const sliderRef = useRef<HTMLDivElement>(null);
  const currentTheme = useTheme();
  const hideCarsousel = useResolution(baseTheme.carousel.breakResolution);

  useSectionOBserver(sliderRef, setHeaderColors(currentTheme, "dark"));

  const handleSlideChanged = useCallback(() => {
    if (!sliderRef.current) return;

    const lastSlickActive =
      sliderRef.current.querySelector(".slick-active-last");

    if (lastSlickActive) {
      lastSlickActive.classList.remove("slick-active-last");
    }

    const activeSlickies = sliderRef.current.querySelectorAll(".slick-active");
    const lastActiveSlick = activeSlickies[activeSlickies.length - 1];

    lastActiveSlick.classList.add("slick-active-last");
  }, []);

  useEffect(() => {
    handleSlideChanged();
  }, [handleSlideChanged]);

  return (
    <ServicesWrapper id="sobre-nos">
      <article>
        <ServicesTypography weight={300} variant="h3" color="light">
          Aqui você tem toda a{" "}
          <Typography color="light" variant="h3" as="span">
            tranquilidade
          </Typography>{" "}
          para viver e focar no seu dia-dia, enquanto nós cuidamos de toda a
          burocracia que te impede de dar o seu melhor.
        </ServicesTypography>

        <ServicesSlider isSingle={hideCarsousel} ref={sliderRef}>
          {hideCarsousel ? (
            renderServicesBox(servicesItems)
          ) : (
            <Slider
              autoplaySpeed={4000}
              autoplay
              arrows={false}
              speed={500}
              slidesToShow={3}
              slidesToScroll={1}
              afterChange={handleSlideChanged}
            >
              {renderServicesBox(clonedServicesItems)}
            </Slider>
          )}
        </ServicesSlider>
      </article>
    </ServicesWrapper>
  );
}

export default Services;
