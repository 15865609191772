import * as React from "react";
import { Helmet } from "react-helmet";
import Home from "@/views/Home";

// markup
function IndexPage() {
  return (
    <>
      <Helmet title="LEAN CONTABILIDADE | Contabilidade Digital | a tranquilidade e agilidade que você precisa" />
      <Home />
    </>
  );
}

export default IndexPage;
