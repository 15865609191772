import styled from "@emotion/styled";
import Typography from "@/components/Typography";

export const ServicesWrapper = styled.section`
  min-height: calc(50vw - 118px);
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  padding-bottom: 15rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding-bottom: 2.5rem;
  }

  article {
    width: 100%;
  }
`;

export const ServicesTypography = styled(Typography)`
  ${({ theme }) => theme.container}

  max-width: 1538px;
  margin-bottom: 4rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    text-align: center;
  }
`;

export const ServicesSlider = styled.div<{ isSingle: boolean }>`
  width: 100%;

  ${({ isSingle }) => !isSingle && "transform: translateX(13%);"}

  .slick-active > div {
    transition: opacity ease-out 0.3s;
  }

  .slick-active-last > div {
    opacity: 0.3;
  }
`;

export const ServicesBoxWrapper = styled.div`
  padding: 1rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding: 2rem;
  }

  > div {
    background: ${({ theme }) => theme.colors.box.background};
    transition: opacity ease-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 35px;
    min-height: 562px;

    p,
    svg {
      color: ${({ theme }) => theme.colors.box.color};
      margin-bottom: 2rem;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.box.hover.background};
    }
  }
`;

export const ServicesBoxTitle = styled(Typography)`
  font-size: 34px;
`;
