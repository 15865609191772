import React, { useRef } from "react";
import { Link } from "gatsby";
import { useTheme } from "@emotion/react";
import Reveal from "react-reveal/Reveal";

import Typography from "@/components/Typography";
import {
  TrustWrapper,
  TrustHeader,
  TrustContent,
  TrustContainer,
  TrustEmptyWrapper,
  TrustFooter,
  TrustBanner,
} from "./styled";
import Button from "@/components/Button/Button";

import WhatsUpIcon from "@/assets/svg/whatsup.svg";
import ArrowLeftIcon from "@/assets/svg/arrow-left.svg";
import RectIcon from "@/assets/svg/rect.svg";
import useSectionOBserver from "@/hooks/useSectionObserver";
import { setHeaderColors } from "@/theme/base/utils";

import trustPersonImage from "./images/trust-person.png";

function Trust() {
  const sectionRef = useRef<HTMLDivElement>(null);
  const currentTheme = useTheme();

  useSectionOBserver(sectionRef, setHeaderColors(currentTheme, "light"));

  return (
    <>
      <TrustWrapper ref={sectionRef}>
        <TrustContainer>
          <TrustHeader>
            <Typography variant="h1" as="h1">
              Contabilidade Digital | a tranquilidade e agilidade que você
              precisa.
            </Typography>
          </TrustHeader>

          <TrustContent>
            <Typography variant="h6" weight={400}>
              Serviços contábeis, fiscais, trabalhistas, societários e
              tributários. Contamos com um atendimento personalizado e uma
              equipe de especialistas sempre disponíveis para atender você de
              forma rápida, eficiente e humanizada.
            </Typography>
          </TrustContent>

          <TrustFooter>
            <Button as={Link} to="https://wa.me/5581994019577">
              <WhatsUpIcon /> Entrar em contato
            </Button>
            <Button
              as={Link}
              to="https://www.instagram.com/leancontabilidade"
              variant="secondary"
            >
              Saiba mais <ArrowLeftIcon />
            </Button>
          </TrustFooter>
        </TrustContainer>

        <TrustBanner banner={trustPersonImage} />
      </TrustWrapper>

      <TrustEmptyWrapper>
        <Reveal effect="visibleRect">
          <RectIcon />
        </Reveal>
      </TrustEmptyWrapper>
    </>
  );
}

export default Trust;
