import React, { useRef } from "react";
import { Link } from "gatsby";
import { useTheme } from "@emotion/react";
import Typography from "@/components/Typography";
import {
  InvestTimeWrapper,
  InvestTimeContainer,
  InvestTimeButtonRow,
  InvestTimeRectWrapper,
} from "./styled";
import Button from "@/components/Button/Button";
import RectIcon from "@/assets/svg/rect.svg";

import WhatsUpIcon from "@/assets/svg/whatsup.svg";
import ArrowLeftIcon from "@/assets/svg/arrow-left.svg";
import useSectionObserver from "@/hooks/useSectionObserver";
import { setHeaderColors } from "@/theme/base/utils";
import useResolution from "@/hooks/useResolution";
import baseTheme from "@/theme/base";

interface InvestTimeProps {
  children?: React.ReactNode;
  contactLink?: string;
  knowMoreLink: string;
  title?: string;
}

function InvestTime({
  children,
  title,
  contactLink = "https://wa.me/5581994019577",
  knowMoreLink = "https://www.instagram.com/leancontabilidade",
}: InvestTimeProps) {
  const sectionRef = useRef(null);
  const currentTheme = useTheme();
  const isTablet = useResolution(baseTheme.breakpoints.tablet);

  useSectionObserver(sectionRef, setHeaderColors(currentTheme, "light"));

  return (
    <InvestTimeWrapper id="invest-time">
      {isTablet && (
        <InvestTimeRectWrapper>
          <RectIcon />
        </InvestTimeRectWrapper>
      )}

      <InvestTimeContainer ref={sectionRef}>
        {Boolean(title) && (
          <Typography
            align="center"
            paragraph
            color="primary"
            variant="h1"
            weight={300}
          >
            {title}
          </Typography>
        )}

        <br />

        {children ? (
          <Typography align="center" color="primary" variant="h1">
            {children}
          </Typography>
        ) : (
          <Typography align="center" color="primary" variant="h1" weight={300}>
            Aqui você tem toda a tranquilidade para{" "}
            <Typography as="span" color="primary" variant="h1" weight={700}>
              viver, trabalhar e curtir sua família
            </Typography>{" "}
            enquanto{" "}
            <Typography as="span" color="primary" variant="h1" weight={700}>
              cuidamos de toda a burocracia.
            </Typography>
          </Typography>
        )}

        <InvestTimeButtonRow>
          <Button
            as={Link}
            to={contactLink}
            variant={isTablet ? "primary" : "secondary"}
          >
            <WhatsUpIcon /> Entrar em contato
          </Button>

          <Button
            as={Link}
            to={knowMoreLink}
            variant={isTablet ? "secondary" : "primary"}
          >
            Saiba mais <ArrowLeftIcon />
          </Button>
        </InvestTimeButtonRow>
      </InvestTimeContainer>
    </InvestTimeWrapper>
  );
}

export default InvestTime;
