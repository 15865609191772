import styled from "@emotion/styled";

export const InvestTimeWrapper = styled.section`
  height: calc(100vh + 5rem);
  background: ${({ theme }) => theme.colors.light};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    height: auto;

    article > div {
      margin-top: 35px;
    }
  }
`;

export const InvestTimeContainer = styled.article`
  max-width: 1390px;
  text-align: center;
  z-index: 2;
  background: ${({ theme }) => theme.colors.light};

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding: 2rem;
    padding-top: 10rem;
  }
`;

export const InvestTimeButtonRow = styled.div`
  margin-top: 5rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    display: inline-flex;
    flex-direction: column;

    a {
      justify-content: center;
    }
  }
`;

export const InvestTimeRectWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 1080px;
  top: -573px;

  &:before {
    content: "";
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    border: solid 598px transparent;
    border-bottom-color: ${({ theme }) => theme.colors.light};
    top: -459px;
    left: -57px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    top: -310px;
    width: 590px;

    &:before {
      top: -174px;
      left: 44px;
      border: solid 250px transparent;
      border-bottom-color: ${({ theme }) => theme.colors.light};
    }
  }
`;
