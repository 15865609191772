import styled from "@emotion/styled";
import Typography from "@/components/Typography";

export const TestimonialsWrapper = styled.section`
  background: url(dot-grid.jpg),
    linear-gradient(180deg, #cfcfcf 8.83%, #ffffff 47.4%, #a5a5a5 100%);
  min-height: calc(100vh + 20rem);
  display: flex;
  align-items: center;

  p {
    color: #161c2d;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding-bottom: 22rem;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    padding-bottom: 12rem;
  }
`;

export const TestimonialsContainer = styled.article`
  ${({ theme }) => theme.container};

  padding-left: 4rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding-left: 2rem;
  }

  div[data-index] {
    text-align: center;
  }
`;

export const TestimonialsSliderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 420px;
  text-align: center;
`;

export const TestimonialsHeader = styled.header`
  display: inline-flex;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  padding-top: 0.5rem;

  &:after {
    width: 100%;
    height: calc(100% - 0.5rem);
    content: "";
    position: absolute;
    top: -4px;
    right: -15px;
    transform: scale(0.95);
    border: solid 4px #000;
    border-radius: 50%;
    z-index: -1;
  }
`;

export const TestimonialImage = styled.img`
  border-radius: 100%;
`;

export const TestimonialDescription = styled(Typography)`
  margin: 48px 0;
`;

export const TestimonialFooter = styled.footer`
  p {
    font-size: 14px;
  }
`;
