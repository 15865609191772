import React, { useRef } from "react";
import { useTheme } from "@emotion/react";
import Slider from "react-slick";
import useSectionOBserver from "@/hooks/useSectionObserver";
import Typography from "@/components/Typography";

import fernandoMoreiraImage from "./images/fernando-moreira.jpg";
import carolTavaresImage from "./images/carol-tavares.jpg";
import joaoCarlosImage from "./images/joao-carlos.jpg";

import {
  TestimonialsWrapper,
  TestimonialsContainer,
  // TestimonialsHeader,
  TestimonialsSliderItem,
  // TestimonialImage,
  TestimonialDescription,
  TestimonialFooter,
} from "./styled";
import useResolution from "@/hooks/useResolution";
import baseTheme from "@/theme/base";

const testimonials = [
  {
    content: `"A LEAN é parceria, transparência e auxílio sempre que preciso. São diferenciados e com uma equipe de qualidade. Sensacional!"`,
    name: "Pedro Victor",
    profession: "Desenvolver de Software",
    location: "Recife - PE",
    image: fernandoMoreiraImage,
  },
  {
    content: `“Queria parabenizar a equipe da LEAN por todo processo que tive até hoje com minha empresa e declaração de IRPF. Tem sido uma experiência incrível.”`,
    name: "Carol Tavares",
    profession: "Médica",
    location: "Recife - PE",
    image: carolTavaresImage,
  },
  {
    content: `”Tinha muita dor de cabeça com a minha contabilidade antes de conhecer a LEAN. Toda a equipe facilita muito o processo, me permitindo focar no crescimento do meu negócio.”`,
    name: "Fernando Santos",
    profession: "Consultor de Negócios",
    location: "Recife - PE",
    image: joaoCarlosImage,
  },
];

const testimonialsItems = Array.from({ length: testimonials.length * 2 }).map(
  (_, index: number) => testimonials[index % testimonials.length]
);

function Testimonials() {
  const sectionRef = useRef<HTMLDivElement>(null);
  const currentTheme = useTheme();

  const isSingle = useResolution(baseTheme.carousel.breakResolution);

  useSectionOBserver(sectionRef, (root) => {
    root.style.setProperty("--header-theme", "transparent");
    root.style.setProperty("--header-logo-theme", currentTheme.colors.primary);
  });

  return (
    <TestimonialsWrapper>
      <TestimonialsContainer ref={sectionRef}>
        <Slider
          autoplaySpeed={4000}
          autoplay
          arrows={false}
          speed={500}
          slidesToShow={isSingle ? 1 : 3}
          slidesToScroll={1}
        >
          {testimonialsItems.map(
            ({ content, name, location, image, profession }, index: number) => (
              <TestimonialsSliderItem key={index}>
                {/* <TestimonialsHeader>
                  <TestimonialImage src={image} alt="Fernando Moreira" />
                </TestimonialsHeader> */}

                <TestimonialDescription
                  variant="h5"
                  weight={300}
                  align="center"
                >
                  {content}
                </TestimonialDescription>

                <TestimonialFooter>
                  <Typography weight={300} align="center" italic>
                    {name} - {profession}
                  </Typography>
                  <Typography weight={300} align="center" italic>
                    {location}
                  </Typography>
                </TestimonialFooter>
              </TestimonialsSliderItem>
            )
          )}
        </Slider>
      </TestimonialsContainer>
    </TestimonialsWrapper>
  );
}

export default Testimonials;
