import React from "react";
import Trust from "@/containers/Trust";
import Services from "@/containers/Services";
import Contact from "@/containers/Contact";
import Testimonials from "@/containers/Testimonials";
import InvestTime from "@/containers/InvestTime";
import Typography from "@/components/Typography";

function Home() {
  return (
    <>
      <Trust />
      <Services />
      <Contact />
      <Testimonials />

      <InvestTime
        title="TRADERTAX"
        contactLink="https://wa.me/5581994019577"
        knowMoreLink="https://www.tradertax.com.br/"
      >
        Simplifique{" "}
        <Typography as="span" color="primary" variant="h1" weight={300}>
          a apuração dos seus impostos de bolsa de valores com
        </Typography>{" "}
        precisão e segurança.
        <Typography as="span" color="primary" variant="h1" weight={300}>
          Crie sua conta, envie suas notas, extratos e
        </Typography>{" "}
        nós cuidamos de todo o resto.{" "}
        <Typography as="span" color="primary" variant="h1" weight={300}>
          Aprovado por
        </Typography>{" "}
        clientes
        <Typography as="span" color="primary" variant="h1" weight={300}>
          ,
        </Typography>{" "}
        assessores e professores de investimento.
      </InvestTime>
    </>
  );
}

export default Home;
