import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { rectAnimation, sharedRectStyles } from "@/theme/base/utils";

const imageAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  } 
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const RectBannerWrapper = styled.figure<{ animationTiming?: number }>`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  width: 60%;
  position: relative;

  .visibleBanner {
    img {
      animation: ${imageAnimation} 1s 1s ease forwards;
    }

    path:first-of-type {
      animation: ${rectAnimation} 3s ease-out forwards;
    }
  }

  img {
    opacity: 0;
  }

  svg {
    position: relative;
    z-index: 2;

    path:first-of-type {
      ${sharedRectStyles}
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 8.5%;
    z-index: 1;
    width: 80%;
    height: 147%;
    right: -33%;
    background: ${({ theme }) => theme.colors.light};
    transform: rotateZ(315deg);
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    display: none;
  }
`;

export const RectBannerImage = styled.img`
  max-width: 78.6%;
  width: 100%;
  position: absolute;
  right: 13.5%;
  top: 0%;
  z-index: 2;
`;
