import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import Typography from "@/components/Typography";

const contactImageTranslation = keyframes`
  0% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0.5rem);
  }
`;

export const ContactWrapper = styled.section`
  min-height: calc(100vh + 20rem);
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    min-height: 100vh;
  }
`;

export const ContactContainer = styled.article`
  ${({ theme }) => theme.container};

  padding-left: 140px;
  position: relative;

  @media only screen and (max-width: 980px) {
    padding: 0 3rem;

    img {
      display: none;
    }
  }
`;

export const ContactTopic = styled(Typography)`
  border-left: 3px solid ${({ theme }) => theme.colors.contact.text};
`;

export const ContactHeader = styled.header`
  width: 50%;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 980px) {
    width: 80%;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    width: 100%;
  }

  h3 {
    margin: 2rem 0 3rem;
  }

  h3,
  h4 {
    color: ${({ theme }) => theme.colors.contact.text};
  }
`;

export const ContactButtonRow = styled.div`
  display: flex;
  grid-column-gap: 1rem;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    display: block;

    a:last-child {
      margin-top: 1rem;
    }
  }
`;

export const ContactImageRow = styled.div`
  position: absolute;
  top: -27rem;
  right: -2rem;
  z-index: 1;

  img {
    width: 100%;
  }

  animation: ${contactImageTranslation} 1.5s ease-in-out alternate infinite;

  @media only screen and (max-width: 1450px) {
    width: 50%;
  }
`;

export const ContactImage = styled.img``;
