import React from "react";
import Reveal from "react-reveal/Reveal";
import { RectBannerImage, RectBannerWrapper } from "./styled";
import RectIcon from "@/assets/svg/rect.svg";

interface IRectBanner {
  banner: string;
  className?: string;
}

function RectBanner({ banner, className }: IRectBanner) {
  return (
    <RectBannerWrapper className={className}>
      <Reveal effect="visibleBanner">
        <div>
          <RectIcon />

          <RectBannerImage src={banner} alt="Person" />
        </div>
      </Reveal>
    </RectBannerWrapper>
  );
}

export default RectBanner;
