import styled from "@emotion/styled";
import { rectAnimation, sharedRectStyles } from "@/theme/base/utils";
import RectBanner from "@/components/RectBanner";

export const TrustWrapper = styled.section`
  background: ${({ theme }) => theme.colors.light};
  height: calc(100vh - 118px);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    height: auto;
    padding-top: 10rem;
  }
`;

export const TrustHeader = styled.header`
  max-width: 820px;

  h1 {
    max-width: 46vw;

    @media only screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tablet}px) {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    margin-bottom: 2rem;
  }
`;

export const TrustContent = styled.article`
  width: 468px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

export const TrustFooter = styled.footer`
  display: inline-flex;

  a:last-child:hover svg {
    transform: translateX(6px);
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    flex-direction: column;
    justify-content: center;

    a {
      justify-content: center;
    }
  }
`;

export const TrustContainer = styled.div`
  position: relative;
  z-index: 3;
  padding-left: 66px;

  ${({ theme }) => theme.container}

  article,
  header {
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    text-align: center;

    h1,
    p {
      text-align: center;
    }
  }
`;

export const TrustEmptyWrapper = styled.div`
  height: 50vw;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.light};
  position: relative;
  z-index: 1;
  overflow: hidden;

  div {
    position: absolute;
    top: -74%;
    right: 13%;
    width: 67%;
  }

  path:first-of-type {
    ${sharedRectStyles}
  }

  .visibleRect path:first-of-type {
    animation: ${rectAnimation} 3s 2s ease-out forwards infinite alternate;
  }
`;

export const TrustBanner = styled(RectBanner)`
  position: absolute;
  top: 0px;
  right: 58px;
`;
