import { useTheme } from "@emotion/react";
import React, { useRef } from "react";
import { Link } from "gatsby";
import useSectionObserver from "@/hooks/useSectionObserver";
import {
  ContactWrapper,
  ContactContainer,
  ContactHeader,
  ContactButtonRow,
  ContactImage,
  ContactImageRow,
  ContactTopic,
} from "./styled";
import Typography from "@/components/Typography";
import Button from "@/components/Button/Button";
import WhatsApp from "@/assets/svg/whatsup.svg";
import CallNowIcon from "@/assets/svg/call-now.svg";
import talkToUsImage from "./images/phone.png";
import { setHeaderColors } from "@/theme/base/utils";

function Contact() {
  const sectionRef = useRef<HTMLDivElement>(null);
  const currentTheme = useTheme();

  useSectionObserver(sectionRef, setHeaderColors(currentTheme, "transparent"));

  return (
    <ContactWrapper id="contact">
      <ContactContainer>
        <ContactHeader>
          <ContactTopic topic as="h4" color="light" variant="body2">
            Dúvidas?
          </ContactTopic>
          <Typography variant="h3" weight={300} color="light" as="h3">
            Fale conosco agora mesmo pelo whatsapp
          </Typography>
        </ContactHeader>

        <ContactButtonRow ref={sectionRef}>
          <Button
            as={Link}
            to="https://wa.me/5581994019577"
            variant="secondary"
          >
            <WhatsApp /> Fale conosco no whatsapp
          </Button>

          <Button as={Link} to="tel:81994019577">
            <CallNowIcon /> Ligue agora
          </Button>
        </ContactButtonRow>

        <ContactImageRow>
          <ContactImage src={talkToUsImage} alt="Olá, como posso te ajudar?" />
        </ContactImageRow>
      </ContactContainer>
    </ContactWrapper>
  );
}

export default Contact;
