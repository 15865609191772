import React from "react";
import { Link } from "gatsby";
import { ServicesBoxWrapper, ServicesBoxTitle } from "../styled";
import Button from "@/components/Button/Button";
import Typography from "@/components/Typography";

interface ServicesBoxProps {
  title: string;
  content: string;
  link: string;
  icon: React.ReactElement;
}

function ServicesBox({ title, content, link, icon }: ServicesBoxProps) {
  return (
    <ServicesBoxWrapper>
      <div>
        {icon}

        <ServicesBoxTitle align="center" variant="h4">
          {title}
        </ServicesBoxTitle>

        <Typography align="center" weight={400} variant="h6">
          {content}
        </Typography>

        <Button variant="primary" as={Link} to={link}>
          Saiba mais
        </Button>
      </div>
    </ServicesBoxWrapper>
  );
}

export default ServicesBox;
